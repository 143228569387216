
import React from 'react';

import apple from '../../assets/icons/apple.png'
import google from '../../assets/icons/google.png'
import bcra from '../../assets/img/usuariosfinancieros.png'


export const FooterScreen = () => {
   
    return (
        <div className="pt-12 ">
            <footer id="footer" className="relative z-50 dark:bg-gray-500 pt-24">
                <div className=" border-t border-b border-gray-200 dark:border-gray-700 py-16 bg-gray-200">
                    <div className="mx-auto container px-4 xl:px-12 2xl:px-4 ">
                        <div className="lg:flex">
                            <div className="w-full lg:w-1/2 mb-16 lg:mb-0 flex">
                                <div className="w-full lg:w-1/2 px-6">        
                                    <ul> 
                                        <li >
                                        <h5 className="leading-none hover:text-brand dark:hover:text-brand text-gray-900 font-black dark:text-gray-50" >Navegación</h5>
                                        </li>
                                        <li className="mt-6">
                                            <a href="/blog" className="text-sm lg:text-sm  hover:text-brand font-light dark:hover:text-brand text-gray-800 dark:text-gray-50">Novedades</a>
                                        </li>
                                        <li className="mt-6">
                                            <a  href="/quienes-somos" className="text-sm lg:text-sm  hover:text-brand font-light dark:hover:text-brand text-gray-800 dark:text-gray-50">Quiénes somos</a>
                                        </li>
                                        <li className="mt-6">
                                            <a href="/contacto" className="text-sm lg:text-sm  hover:text-brand font-light dark:hover:text-brand text-gray-800 dark:text-gray-50">Contacto</a>
                                        </li>
                                        <li className="mt-6">
                                            <a href="https://www.notion.so/B-squeda-Developer-c2a2efd3c9174147a54e431e2f37cf1b" className="text-sm lg:text-sm  hover:text-brand font-light dark:hover:text-brand dark:text-gray-50">Trabaja con nosotros</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="w-full lg:w-1/2 px-6">
                                    <ul>
                                        <li>
                                            <h6 className="font-black">Legales</h6>
                                        </li>
                                        <li className="mt-6">
                                            <a href="/termino-servicio" className="text-sm lg:text-sm  hover:text-brand dark:hover:text-brand text-gray-800 dark:text-gray-50">Terminos de servicio</a>
                                        </li>
                                        <li className="mt-6">
                                            <a className="text-sm lg:text-sm  hover:text-brand dark:hover:text-brand text-gray-800 dark:text-gray-50">Estado del sistema 
                                            <span className="px-2 ml-2 inline-flex text-sm leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                Activo
                                            </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/2 flex">
                                <div className="w-full lg:w-1/2 px-6">
                                    <ul>
                                        <li>
                                            <h6 className="font-black" >Billetera</h6>
                                        </li>
                                        <li className="mt-4">
                                            <a>
                                                <img src={apple} className="h-12 w-auto rounded-md" />
                                            </a>
                                        </li>
                                        <li className="mt-2">
                                                <a>
                                                    <img src={google} className="h-12 w-auto rounded-md" />
                                                </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="w-full lg:w-1/2 flex flex-col">
                                <div className="flex items-center mb-3">
                                        <a href="https://www.instagram.com/serviciosurbanosok/">
                                            <div className="pl-4">
                                                <i className="fab fa-instagram"></i>
                                            </div>
                                        </a>
                                        <a href="https://www.facebook.com/Servicios-Urbanos-SA-555801397865103/">
                                            <div className="pl-4">
                                                <i className="fab fa-facebook-f" ></i>
                                            </div>
                                        </a>
                                        <a href="https://api.whatsapp.com/send?phone=543765022276&text=Hola,%20necesito%20solicitar%20un%20turno%20para%20adquirir%20el%20beneficio%20beg">
                                            <div className="pl-4">
                                                <i className="fab fa-whatsapp"></i>
                                            </div>
                                        </a>
                                        
                                </div>
                                    <ul> <li> <a> <img src={bcra} className="w-auto rounded-md" /> </a> </li> </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3 flex flex-col justify-center items-center">
                    <p className="mt-2 text-xs lg:text-sm  text-gray-900 dark:text-gray-50">2021 Servicios Urbanos S.A. Todos los derechos reservados</p>
                </div>
            </footer>
        </div>
    );
}