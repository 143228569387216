import React, { useState, lazy } from "react";
import "../index.css";
import billetera from "../assets/img/Group-4.png";
import banner from "../assets/img/Group-3.png";
import walletfour from "../assets/img/03052021-IMG_2347.jpeg";
import walletone from "../assets/img/03052021-IMG_2383.jpeg";
import wallettwo from "../assets/img/03052021-IMG_2406.jpeg";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const Item = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="border rounded shadow-sm">
        <button
          type="button"
          aria-label="Open item"
          title="Open item"
          className="flex items-center justify-between w-full p-4 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className="text-lg font-medium">{title}</p>
          <div className="flex items-center justify-center w-8 h-8 border rounded-full">
            <svg
              viewBox="0 0 24 24"
              className={`w-3 text-gray-600 transition-transform duration-200 ${
                isOpen ? "transform rotate-180" : ""
              }`}
            >
              <polyline
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeMiterlimit="10"
                points="2,7 12,17 22,7"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
        {isOpen && (
          <div className="p-4 pt-0">
            <p className="text-gray-700">{children}</p>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium">{title}</p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  );
};
export const WalletScreen = () => {
  const [menu, setMenu] = useState(false);

  return (
    <>
      <div>
        <section>
          <div className="w-full relative px-6 xl:px-0">
            <div className="pt-1 lg:flex items-center relative z-10 container mx-auto">
              <div className="w-full lg:w-1/2 h-full lg:pr-10 xl:pr-0">
                <img className="mx-auto" src={billetera} />
              </div>
              <div className="w-full lg:w-1/2 h-full">
                <p className="text-yellow-500 text-2xl mb-4">
                  Billetera Electrónica
                </p>
                <h1 className="text-gray-900 text-4xl md:text-4xl mb-8  ">
                  Presentamos la nueva billetera electrónica para abonar el
                  transporte público
                </h1>
                <p className="text-gray-800 font-regular mb-8">
                  La nueva Billetera Electrónica permite a los usuarios
                  movilizarse sin llevar dinero en efectivo, autogestionar la
                  recarga de crédito para viajar en transporte público, evitar
                  el trámite presencial de registro. Por todo esto colabora con
                  los protocolos de seguridad sanitaria y contribuye a la
                  seguridad vial al eximir al conductor de la gestión de cobro
                  del pasaje.
                </p>
                <div className="bg-white py-4 px-4 grid justify-items-center  sm:justify-center shadow-lg rounded-lg">
                  <div className="flex">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-yellow-500 icon icon-tabler mr-2 icon-tabler-layout-bottombar"
                        width={16}
                        height={16}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x={4} y={4} width={16} height={16} rx={2} />
                        <line x1={4} y1={15} x2={20} y2={15} />
                      </svg>
                      <p className="text-center tracking-normal text-gray-800">
                        Comodidad
                      </p>
                    </div>
                    <div className="flex items-center ml-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-yellow-500 icon icon-tabler icon-tabler-flag"
                        width={16}
                        height={16}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1={5} y1={5} x2={5} y2={21} />
                        <line x1={19} y1={5} x2={19} y2={14} />
                        <path d="M5 5a5 5 0 0 1 7 0a5 5 0 0 0 7 0" />
                        <path d="M5 14a5 5 0 0 1 7 0a5 5 0 0 0 7 0" />
                      </svg>
                      <p className="leading-none tracking-normal text-gray-800 ml-2.5">
                        Seguridad
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <style>
          {`
            /* Top menu */
            .top-100 {
                animation: slideDown 0.5s ease-in-out;
            }
            @keyframes slideDown {
                0% {
                    top: -50%;
                }
                100% {
                    top: 0;
                }
            }
            * {
                outline: none !important;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                -webkit-tap-highlight-color: transparent;
                font-family: 'Noto Sans', sans-serif;
            }`}
        </style>
      </div>
      {/* steps */}
      <div className="mt-14 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-6 row-gap-10 lg:grid-cols-2">
          <div className="lg:py-6 lg:pr-16">
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                    <svg
                      className="w-4 text-gray-600"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <line
                        fill="none"
                        strokeMiterlimit="10"
                        x1="12"
                        y1="2"
                        x2="12"
                        y2="22"
                      />
                      <polyline
                        fill="none"
                        strokeMiterlimit="10"
                        points="19,15 12,22 5,15"
                      />
                    </svg>
                  </div>
                </div>
                <div className="w-px h-full bg-gray-300" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold">¿Cómo funciona?</p>
                <p className="text-gray-700">
                  El usuario descarga la aplicación en su celular, transfiere
                  crédito a su billetera electrónica desde su cuenta bancaria y
                  puede comenzar a utilizarla
                </p>
                <p className="mt-2 text-gray-700">
                  En el ómnibus: En cada coche se ubican 3 validadores.
                </p>
                <p className="mt-2 text-gray-700">
                  Al subir el usuario debe iniciar su viaje pasando el código QR
                  de la billetera por el validador que se encuentra detrás del
                  chofer. Antes de descender, debe cerrar el viaje pasando
                  nuevamente el código QR de la aplicación por alguno de los dos
                  validadores que se encuentran ubicados en las puertas de
                  atrás. De esta manera se abonan sólo los recorridos que
                  correspondan.
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                    <svg
                      className="w-4 text-gray-600"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <line
                        fill="none"
                        strokeMiterlimit="10"
                        x1="12"
                        y1="2"
                        x2="12"
                        y2="22"
                      />
                      <polyline
                        fill="none"
                        strokeMiterlimit="10"
                        points="19,15 12,22 5,15"
                      />
                    </svg>
                  </div>
                </div>
                <div className="w-px h-full bg-gray-300" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold">Descarga</p>
                <p className="text-gray-700">
                  Desde las plataformas Google Play o App Store.
                </p>
                <p className="text-gray-700">
                  Como en cualquier APP se deben seguir los pasos indicados para
                  registrarse y tener la billetera electrónica. Al completar el
                  proceso de registro, recibirás un email de confirmación.
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                    <svg
                      className="w-4 text-gray-600"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <line
                        fill="none"
                        strokeMiterlimit="10"
                        x1="12"
                        y1="2"
                        x2="12"
                        y2="22"
                      />
                      <polyline
                        fill="none"
                        strokeMiterlimit="10"
                        points="19,15 12,22 5,15"
                      />
                    </svg>
                  </div>
                </div>
                <div className="w-px h-full bg-gray-300" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold">Cómo Cargo Crédito</p>
                <p className="text-gray-700">
                  Existen 3 modelos de carga de crédito:
                </p>
                <ul>
                  <li>
                    <p className="mt-2 text-gray-700">
                      1.Banco: Desde una operación de home banking, seleccionas
                      la opción Transferencia y acreditás el monto que deseas a
                      tu Billetera Electrónica
                    </p>
                  </li>
                  <li>
                    <p className=" mt-2 text-gray-700">
                      2.Terminales y Comercios adheridos: podés recargar tu
                      Billetera Electrónica en las boleterías habilitadas en las
                      Terminales de Transferencia y comercios adheridos que
                      funcionan como puntos de recarga.
                    </p>
                  </li>
                  <li>
                    <p className=" mt-2 text-gray-700">
                      3.Transferencia entre Usuarios: En tu billetera encontrás
                      un Botón Central que te lleva a la opción Envío de Dinero.
                      Transferís el monto que desees al CBV/CVU o alias que
                      elijas.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                    <svg
                      className="w-4 text-gray-600"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <line
                        fill="none"
                        strokeMiterlimit="10"
                        x1="12"
                        y1="2"
                        x2="12"
                        y2="22"
                      />
                      <polyline
                        fill="none"
                        strokeMiterlimit="10"
                        points="19,15 12,22 5,15"
                      />
                    </svg>
                  </div>
                </div>
                <div className="w-px h-full bg-gray-300" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold">Para Pagar</p>
                <p className="text-gray-700">
                  La billetera Electrónica de Servicios Urbanos está habilitada
                  para abonar con ella el transporte público del Sistema
                  Integrado de Transporte y productos y servicios de comercios
                  adheridos a la aplicación.
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                    <svg
                      className="w-4 text-gray-600"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <line
                        fill="none"
                        strokeMiterlimit="10"
                        x1="12"
                        y1="2"
                        x2="12"
                        y2="22"
                      />
                      <polyline
                        fill="none"
                        strokeMiterlimit="10"
                        points="19,15 12,22 5,15"
                      />
                    </svg>
                  </div>
                </div>
                <div className="w-px h-full bg-gray-300" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold">Menores</p>
                <p className="text-gray-700">
                  En el caso de los menores con celular sus BE estarán asociadas
                  a las cuentas de sus tutores quienes serán los encargados de
                  realizar la recarga desde sus cuentas bancarias. Los menores
                  tendrán en su celular sus propias credenciales: usuario y
                  contraseña en su BE.
                </p>
                <p className=" mt-2 text-gray-700">
                  Los menores que no cuenten con celular pueden tener su cuenta
                  en la billetera de sus padres o tutores. En un celular se
                  pueden coexistir Cuentas Asociadas, al momento de registrar al
                  menor en una cuenta asociada se trasferirán a esa cuenta los
                  beneficios que pueda tener, como el Boleto Estudiantil
                  Gratuito. Al subir al ómnibus, se selecciona la cuenta desde
                  la cual se necesita abonar el pasaje. También puede seguir
                  utilizando su tarjeta Sube Misionero.
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                    <svg
                      className="w-6 text-gray-600"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <polyline
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        points="6,12 10,16 18,8"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="pt-1">
                <p className="mb-2 text-lg font-bold">Listo</p>
                <p className="text-gray-700" />
              </div>
            </div>
          </div>
          <div className="relative">
            <img
              className="inset-0 object-cover object-bottom w-full rounded shadow-lg h-96 lg:absolute lg:h-full"
              src={banner}
              alt=""
            />
          </div>
        </div>
      </div>
      {/* //Seguridad */}
      <div className="mt-4 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-yellow-500 uppercase rounded-full bg-teal-accent-400">
              Seguridad
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="2feffae2-9edf-414e-ab8c-f0e6396a0fc1"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#ff7a2f-9edf-414e-ab8c-f0e6396a0fc1)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative"></span>
            </span>{" "}
            La aplicación es segura
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            Todas las transacciones son realizadas bajo métodos de cifrados de
            ultima generación.
          </p>
        </div>
        <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
          <div className="grid grid-cols-2 gap-5">
            <img
              className="object-cover w-full h-56 col-span-2 rounded shadow-lg"
              src={walletfour}
              alt=""
            />
            <img
              className="object-cover w-full h-48 rounded shadow-lg"
              src={wallettwo}
              alt=""
            />
            <img
              className="object-cover w-full h-48 rounded shadow-lg"
              src={walletone}
              alt=""
            />
          </div>
          <div className="flex flex-col justify-center">
            <div className="pb-4 mb-4">
              <h6 className="mb-2 font-semibold leading-5">Es segura</h6>
              <p className="text-sm text-gray-900"> </p>
            </div>
            <div className="pb-4 mb-4 ">
              <div className="flex">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-yellow-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <h6 className="mb-2 font-semibold leading-5">
                    Todas las transacciones son realizadas bajo métodos de
                    cifrados de última generación.
                  </h6>
                </div>
              </div>
            </div>
            <div className="pb-4 mb-4 ">
              <div className="flex">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-yellow-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <h6 className="mb-2 font-semibold leading-5">
                    Todas las transacciones son notificadas a los usuarios, como
                    débito / crédito, según corresponda.
                  </h6>
                </div>
              </div>
            </div>
            <div className="pb-4 mb-4 ">
              <div className="flex">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-yellow-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <h6 className="mb-2 font-semibold leading-5">
                    Las transacciones están cubiertas por las mismas
                    protecciones de seguridad y privacidad que las operaciones
                    bancarias.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Descargar app */}
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-lg sm:text-center sm:mx-auto">
          <a
            href="/"
            aria-label="Go Home"
            title="Logo"
            className="inline-block mb-4"
          >
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-yellow-100">
              <svg
                className="w-10 h-10 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
          </a>
          <h2 className="mb-6 font-sans text-4xl  tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-32 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="6b0188f3-b7a1-4e9b-b95e-cad916bb3042"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(bg-yellow-500-b7a1-4e9b-b95e-cad916bb3042)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative bg-white"></span>
            </span>{" "}
            Descarga la app
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            Desde las plataformas Google Play o App Store. Como en cualquier APP
            se deben seguir los pasos indicados. Al finalizar se crea un usuario
            y una contraseña para completar el proceso de registro. Al completar
            el registro el usuario recibirá un email con la confirmación
          </p>
          <hr className="my-8 border-gray-300" />
          <div className="flex items-center mb-3 sm:justify-center">
            <a
              href="/"
              className="mr-3 transition duration-300 hover:shadow-lg"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/1000px-Download_on_the_App_Store_Badge.svg.png"
                className="object-cover object-top w-32 mx-auto"
                alt=""
              />
            </a>
            <a href="/" className="transition duration-300 hover:shadow-lg">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1000px-Google_Play_Store_badge_EN.svg.png"
                className="object-cover object-top w-32 mx-auto"
                alt=""
              />
            </a>
          </div>
          <p className="max-w-xs text-xs text-gray-600 sm:text-sm sm:max-w-sm sm:mx-auto">
            Para poder tener tu billetera debes de ser mayor de 12 años, contar
            con un telefono y datos moviles.
          </p>
        </div>
      </div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            ¿Cómo Cargo Crédito?
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            Existen 3 modelos de carga de crédito
          </p>
        </div>
        <div className="grid gap-8 row-gap-8 lg:grid-cols-3">
          <div className="sm:text-center">
            <div className="flex items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-red-100 sm:mx-auto">
              1
            </div>
            <h6 className="mb-2 font-semibold leading-5">Modelo 1</h6>
            <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
              Banco: Desde una operación de home banking, seleccionas la opción
              Transferencia y acreditás el monto que deseas a tu Billetera
              Electrónica
            </p>
          </div>
          <div className="sm:text-center">
            <div className="flex items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-red-100 sm:mx-auto">
              2
            </div>
            <h6 className="mb-2 font-semibold leading-5">Modelo 2</h6>
            <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
              Terminales y Comercios adheridos: podés recargar tu Billetera
              Electrónica en las boleterías habilitadas en las Terminales de
              Transferencia y comercios adheridos que funcionan como puntos de
              recarga.
            </p>
          </div>
          <div className="sm:text-center">
            <div className="flex items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-red-100 sm:mx-auto">
              3
            </div>
            <h6 className="mb-2 font-semibold leading-5">Modelo 3</h6>
            <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
              Transferencia entre Usuarios: En tu billetera encontrás un Botón
              Central que te lleva a la opción Envío de Dinero. Transferís el
              monto que desees al CBV/CVU o alias que elijas.
            </p>
          </div>
        </div>
      </div>
      <div className="grid gap-8 row-gap-10 lg:grid-cols-2">
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-red-50 sm:mx-auto sm:w-24 sm:h-24">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
              />
            </svg>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5 font-sans text-3xl text-gray-900 sm:text-4xl">
            Comercios Adheridos{" "}
          </h6>
          <p className="mb-3 text-sm text-gray-900">
            Quiero sumar esta modalidad de pago a mi negocio Quiero ser Punto de
            Recarga
          </p>
          <a
            href="/comercios"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Ver más
          </a>
        </div>
        <div className="max-w-md sm:mx-auto sm:text-center">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-red-50 sm:mx-auto sm:w-24 sm:h-24">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
          </div>
          <h6 className="mb-3 text-xl font-bold leading-5 font-sans text-3xl text-gray-900 sm:text-4xl">
            Cobros con QR
          </h6>
          <p className="mb-3 text-sm text-gray-900">
            Quiero cobrar mis productos o servicios con la Billetera Electrónica
            Susa
          </p>
          <a
            href="/qr"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Ver más
          </a>
        </div>
      </div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            Preguntas Frecuentes
          </h2>
        </div>
        <div className="grid gap-8 row-gap-10 lg:grid-cols-2">
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-yellow-50 sm:mx-auto sm:w-24 sm:h-24">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </div>
            <h6 className="mb-3 text-xl font-bold leading-5">Para Pagar</h6>
            <p className="mb-3 text-sm text-gray-900">
              La billetera Electrónica de Servicios Urbanos está habilitada para
              abonar con ella el transporte público del Sistema Integrado de
              Transporte y productos y servicios de comercios adheridos a la
              aplicación.
            </p>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-yellow-50 sm:mx-auto sm:w-24 sm:h-24">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                />
              </svg>
            </div>
            <h6 className="mb-3 text-xl font-bold leading-5">Es segura</h6>
            <p className="mb-3 text-sm text-gray-900">
              Todas las transacciones son realizadas bajo métodos de cifrados de
              última generación. Todas las transacciones son notificadas a los
              usuarios, como débito / crédito, según corresponda. Las
              transacciones están cubiertas por las mismas protecciones de
              seguridad y privacidad que las operaciones bancarias.
            </p>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-yellow-50 sm:mx-auto sm:w-24 sm:h-24">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </div>
            <h6 className="mb-3 text-xl font-bold leading-5">Menores</h6>
            <p className="mb-3 text-sm text-gray-900">
              En el caso de los{" "}
              <b>menores con celular sus Boleto Estudiantil</b> estarán
              asociadas a las cuentas de sus tutores quienes serán los
              encargados de realizar la recarga desde sus cuentas bancarias. Los
              menores tendrán en su celular sus propias credenciales: usuario y
              contraseña en su BE.
            </p>
            <p className="mt-2 mb-3 text-sm text-gray-900">
              Los menores que no cuenten con celular pueden tener su cuenta en
              la billetera de sus padres o tutores. En un celular se pueden
              coexistir <b>Cuentas Asociadas</b>, al momento de registrar al
              menor en una cuenta asociada se trasferirán a esa cuenta los
              beneficios que pueda tener, como el Boleto Estudiantil Gratuito.
              Al subir al ómnibus, se selecciona la cuenta desde la cual se
              necesita abonar el pasaje. También puede seguir utilizando su
              tarjeta Sube Misionero.
            </p>
          </div>
        </div>
      </div>
      {/*Preguntas frecuentes */}
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div class="flex flex-col mb-16 sm:text-center">
            <a href="/" class="mb-6 sm:mx-auto">
              <div class="flex items-center justify-center w-12 h-12 rounded-full bg-red-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </a>
            <div class="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
              <p class="text-base text-gray-700 md:text-lg text-2xl">
                Queremos ofrecerte un excelente servicio, por eso dejamos a tu
                disposición algunas respuestas a preguntas frecuentes.
              </p>
            </div>
          </div>
          <div class="space-y-4">
            <Item title="¿La aplicación es gratuita?">
              Si, la podés descargas en forma gratuita desde las plataformas
              Google Play y App Store.
            </Item>
            <Item title="¿Qué pasa si no cierro el viaje?">
              Lo ideal es que puedas cerrar tu viaje y de esa forma sólo abonás
              lo que corresponde del viaje, de lo contrario, el sistema cerrará
              el viaje cobrando la tarifa máxima.
            </Item>
            <Item title="¿Hay reintegros si olvido cerrar la app?">
              No, por eso es muy importante que la cierres al bajar, así abonás
              solo lo que corresponde.
            </Item>
            <Item title="¿Si no tengo cuenta bancaria cómo recargo mi Billetera Electrónica?">
              Se puede recargar pagando en efectivo en puntos de recarga o
              comercios adheridos o transferencia desde otro usuario.
            </Item>
            <Item title="¿Se podrá recargar la app con Mercado Pago?">
              Si, se puede pagar con todos los métodos electrónicos a tu
              alcance.
            </Item>
            <Item title="¿Qué pasa si me quedo sin crédito en mi Billetera Electrónica?">
              Así como la tarjeta Sube Misionero tiene un saldo negativo a tu
              favor, la Billetera Electrónica Susa habilita un saldo socorro
              (S.O.S) de $ 160.
            </Item>
            <Item title=" ¿Qué pasa si no tengo datos?">
              Si no contás con datos en tu celular, podés generar un QR ÚNICO
              con el botón QR PASAJE que aparece al iniciar la aplicación. Con
              este código, que tiene una duración de 2 horas, generado
              previamente, podrá abonar su viaje. Además, las terminales tienen
              wifi libre, también podrás generar ese QR Pasaje allí. Casi la
              totalidad de las líneas pasan por alguna de las terminales en sus
              recorridos.
            </Item>
            <Item title="¿Cómo hago la carga de crédito en la BE SUSA?">
              Transfiriendo fondos a tu billetera electrónica desde su propia
              cuenta bancaria, a través de la modalidad TRANSFERENCIAS. En el
              momento en que te registrás en la aplicación, el sistema va a
              generar un alias y CVU para vos, el que identificará a tu
              billetera al momento de realizar la transferencia de fondos desde
              tu cuenta bancaria. También puede cargar saldo en las Terminales
              de Transferencia, Centro de Atención al Cliente de S.U.S.A. o en
              puntos de recarga, solo indicando el DNI.
            </Item>
            <Item title="¿Se puede pagar más de un pasaje?">
              No, porque la Billetera es única e intransferible, excepto que se
              trate de un menor de edad asociado a la cuenta de sus padres o
              tutores, quien tendrá su propio QR identificador.
            </Item>
            <Item title="¿Cómo transfiero fondos a otro usuario?">
              Desde tu billetera electrónica siguiendo estos pasos: 1 - Botón
              central - 2 - Envío de dinero – 3 - Transferir a un CBV/CVU o
              alias.
            </Item>
            <Item title="¿Se puede transferir fondos desde otra cuenta?">
              Si, la billetera electrónica es proveedor de servicios de pago
              (PSP) como algunas de las billeteras más conocidas. Esto significa
              que podrás transaccional con ellas a la vez.
            </Item>
            <Item title="¿Por qué la BE Susa es intransferible?">
              Es intransferible para administrar y asegurar los beneficios como:
              la gratuidad para mayores de 70 años, ex combatientes de Malvinas,
              discapacitados, BEEG, entre otros.
            </Item>
            <Item title="¿Qué pasa si me quedo sin batería en el celular? ">
              Si tu celular se queda sin baterías antes de subir al ómnibus,
              podrás utilizar la tarjeta Sube Misionero o pagar en efectivo. Si
              queda sin baterías una vez iniciado el viaje, y al no poder pasar
              la app por el validador de cierre, el sistema cerrará el viaje
              cobrando la tarifa máxima. Pero, estamos equipando las unidades y
              las terminales de transferencia con salidas USB que te permitan
              recargar el celular ante estas situaciones.
            </Item>
            <Item title="¿Cómo harán el control los inspectores?">
              Los inspectores contarán con teléfonos inteligentes y una
              aplicación especial para las verificaciones.
            </Item>
            <Item title="¿Cómo controla el chofer que se haya abonado el pasaje?">
              Los choferes tendrán su propia pantalla que se encenderá
              anunciando si el pago del boleto fue correcto, o si no se pudo
              efectuar.
            </Item>
            <Item title="¿Cómo calcula las distancias de mi viaje?">
              Los viajes se abonan como siempre. El sistema cobra por secciones:
              Posadas, Garupá, Candelaria, Fachinal, etc. Podrás utilizar la
              billetera en viajes de media distancia en ómnibus que tengan el
              sistema instalado.
            </Item>
            <Item title="¿Es ágil la aplicación?">
              El sistema de validadores del BE Susa está programado para que el
              acto de validación se desarrolle en 1 segundo y medio.
            </Item>
            <Item title="¿La aplicación es segura?">
              Totalmente, con estándares de seguridad y encriptación más
              avanzados del mundo.
            </Item>
            <Item title="¿Los menores pueden tener BE SUSA?">
              Si, todos pueden tenerla. En el caso de los menores con celular
              sus BE estarán asociadas a las cuentas de sus tutores quienes
              serán los encargados de realizar la recarga desde sus cuentas
              bancarias. Los menores tendrán sus propias credenciales: usuario y
              contraseña en su BE.
            </Item>
            <Item title="¿Cómo hacen los menores sin celular?">
              En un celular se pueden coexistir Cuentas Asociadas, para que el
              padre/madre o tutor abone el pasaje de los menores a su cargo.
              Como los menores de 12 años no pueden registrarse, una persona
              mayor debe asociarlo a su Billetera, desde la sección ASOCIADOS de
              la aplicación. También puede seguir utilizando su tarjeta Sube
              Misionero.
            </Item>
            <Item title="¿Qué pasa en los descensos numerosos? ">
              Hay dos validadores para descender, cada pasajero podrá cerrar el
              viaje en 1 segundo y medio. El siguiente pasajero debe esperar
              solo ese tiempo para proceder a cerrar su viaje. Recomendación:
              Pasá tu QR después que el validador quede con la pantalla en
              verde, aprobando el cierre del viaje del pasajero anterior.
            </Item>
            <Item title="¿El uso de la billetera electrónica es obligatorio?">
              No. Por el momento es un medio de pago más. Si en algún momento se
              deja de utilizar la Tarjeta Sube Misionero, los que quieran tener
              los beneficios sociales van a tener que contar con la billetera
              porque es el sistema que permite la administración de estos
              beneficios y un mejor control de las autoridades.
            </Item>
            <Item title="¿Otras opciones de abonar el pasaje? ">
              Se puede abonar también con dinero en efectivo y con la tarjera
              Sube Misionero.
            </Item>
            <Item title="¿Con la BE se pueden abonar los viajes interurbanos?">
              Si. La Billetera Electrónica de Servicios Urbanos está operativa
              en el área metropolitana de Posadas y en las localidades que
              conecta el Sistema Integrado de Transporte: Profundidad, Fachinal,
              Parada Leis, Candelaria y Garupá.
            </Item>
            <Item title="¿Qué pasa si el validador no tiene internet en zona en las que no hay conexión?">
              El validador tiene una memoria interna que registra tanto la
              apertura como el cierre de cada viaje. Cuando accede nuevamente a
              conexión envía esa información al sistema para así poder gestionar
              el crédito o débito por cada pasaje.
            </Item>
            <Item title="¿La Billetera Electrónica SUSA se usa sólo para pagar el transporte público?">
              No, también está habilitada para el pago de productos y servicios
              en comercios adheridos.
            </Item>
            <Item title="¿Si no tengo celular? ">
              Las personas que no cuenten con celular, podrán seguir utilizando
              el pago en efectivo y la tarjeta Sube Misionero.
            </Item>
            <Item title="¿El uso de la BE modifica el tiempo de marcha? ">
              No. El sistema fue planificado teniendo en cuanta todos estos
              detalles. Actualmente, con el uso de la tarjeta Sube Misionero, se
              destinan 4 o 5 segundos por pasajero para indicar el destino al
              conductor. La Billetera Electrónica habilita el viaje en 1 segundo
              y medio, la diferencia de estos tiempos, se destinan a los
              segundos de cierre del viaje al descender del coche.
            </Item>
          </div>
        </div>
      </div>
    </>
  );
};
