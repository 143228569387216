import React from 'react';
import  { Navbar } from '../components/ui/Navbar';
import { Switch, Route,  Redirect } from 'react-router-dom';
//views
import { AboutScreen } from '../views/AboutScreen';
import { TerminalScreen } from '../views/TerminalScreen';
import { CustomersScreen } from '../views/CustomersScreen';
import { SegurityScreen } from '../views/SegurityScreen';
import { FooterScreen } from '../components/footer/FooterScreen';
import { DashboardScreen } from '../views/DashboardScreen';
import { QuestionScreen } from '../views/QuestionScreen';
import { BlogScreen } from '../views/BlogScreen';
import ContactScreen  from '../views/ContactScreen';
import { WalletScreen } from '../views/WalletScreen';
import {TermServiceScreen } from '../views/TermServiceScreen';
import { SubeScreen } from '../views/SubeSreen';
import { bondiScreen } from '../views/bondiScreen';
import { StoreScreen } from '../views/StoreScreen';
import { PaymentsScreen } from '../views/PaymentsScreen';




export const DashboardRoutes = () => {
    return (
        <>
            <Navbar />

            <div className="container mt-2">
                <Switch>
                    <Route path="/quienes-somos" component={ AboutScreen } />
                    <Route  path="/terminales" component={ TerminalScreen } />
                    <Route exact path="/atencion" component={ CustomersScreen } />
                    <Route exact path="/seguridad" component={ SegurityScreen } /> 
                    <Route path="/preguntas" component={ QuestionScreen } />
                    <Route path="/blog" component={ BlogScreen } />
                    <Route path="/contacto" component={ ContactScreen } />
                    <Route path="/billetera" component={ WalletScreen } />
                    <Route path="/comercios" component={ StoreScreen } />
                    <Route path="/qr" component={ PaymentsScreen } />
                    <Route path="/termino-servicio" component={ TermServiceScreen } />
                    <Route path="/sube" component={ SubeScreen } />
                    <Route path="/bondi" component={ bondiScreen} />
                    <Route path="/inicio" component={ DashboardScreen } />
                    <Redirect to="/inicio" />
                </Switch>
            </div>
            <FooterScreen /> 

        </>
    )
}
