import React from 'react'


export const TermServiceScreen = () => {
    return (
        <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
        <div className="lg:w-1/2">
          <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
          Politica de Privacidad
          </h2>
        </div>
        <div className="lg:w-1/2">
          <p className="text-base text-gray-700 md:text-sm">
          Servicios Urbanos S.A. se preocupa por la privacidad y quiere que usted conozca 
          la forma en que recopilamos, utilizamos y/o divulgamos la información que nos 
          proporcionan tanto usted como terceros. Esta política de privacidad describe 
          nuestras prácticas en relación con la información que nosotros o nuestros proveedores 
          de servicios recopila a través de cualquier sitio web 
          o aplicación (incluyendo sitios web, aplicaciones móviles, etc.).
          </p>
        </div>
      </div>
      <div className="grid gap-8 row-gap-10 sm:grid-cols-2 lg:grid-cols-4 mt-6">
        <div className="max-w-md">
          
          <h6 className="mb-2 font-semibold leading-5">Datos personales.</h6>
          <p className="mb-3 text-sm text-gray-900">
          Constituye un dato personal cualquier información relativa a una persona física o jurídica, 
          identificada o identificable, aunque sea indirectamente, mediante referencia a cualquier otra información.
          </p>
        </div>
        <div className="max-w-md ">
          
          <h6 className="mb-2 font-semibold leading-5">Lugar</h6>
          <p className="mb-3 text-sm text-gray-900">
          Los datos serán tratados en la sede operativa del responsable de guarda, 
          así como en otros lugares en los que se encuentren situadas 
          las partes que también estén involucradas en dicho tratamiento. 
          Para más información, por favor póngase en contacto con el responsable de tratamiento.
          </p>
        </div>
        <div className="max-w-md ">
         
          <h6 className="mb-2 font-semibold leading-5">Período de conservación de datos</h6>
          <p className="mb-3 text-sm text-gray-900">
          Los datos serán guardados durante el plazo necesario para 
          prestar el servicio solicitado por el usuario, 
          o el que se requiera en función de las finalidades descritas en este documento, 
          y el usuario tendrá en todo momento la facultad de solicitar la interrupción
          de su guarda o la cancelación de los datos.
          </p>
        </div>
        <div className="max-w-md">
          
          <h6 className="mb-2 font-semibold leading-5">Logeo del sistema</h6>
          <p className="mb-3 text-sm text-gray-900">
          Por cuestiones relativas al funcionamiento y mantenimiento de la aplicación,
           ésta o cualequier otro servicio de terceros que se utilice podrán recoger un Log del sistema, 
           esto es, archivos que registren las interacciones 
          y que pueden contener datos personales, tales como -y no exclusivamente- la dirección IP del usuario.
          </p>
        </div>
      </div>
    </div>

    {/*Terminos de servicio */}
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="70326c9b-4a0f-429b-9c76-792941e326d5"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#70326c9b-4a0f-429b-9c76-792941e326d5)"
                  width="52"
                  height="24"
                />
              </svg>
            </span>{' '}
            La transparencia con nuestros usuarios es TODO
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            
          </p>
        </div>
      </div>
      <div className="max-w-screen-xl sm:mx-auto">
        <div className="grid grid-cols-1 gap-16 row-gap-8 lg:grid-cols-2">
          <div className="space-y-8">
            <div>
              <p className="mb-4 text-xl font-medium">
              Responsable de guarda de los datos y titular
              </p>
              <p className="text-gray-700">
                Al usar el servicio o interactuar con él, 
                el usuario nos proporciona una serie de datos personales, 
                conforme surge de la planilla a completar en la aplicación. 
                Los datos personales pueden ser proporcionados por el usuario por 
                su propia voluntad, o bien ser recogidos automáticamente durante la utilización
                 de la aplicación o de otras que se vinculen con la misma. Salvo que se indique lo contrario, 
                 el uso de cookies -o de otras medios de seguimiento- por esta aplicación u otras vinculadas o 
                 por los propietarios de servicios de terceros utilizados por estas aplicaciones tiene como finalidad 
                 la identificación de los usuarios y el registro de sus preferencias, con la finalidad de prestar el 
                 servicio solicitado por el usuario. Se autoriza a transmitir la información fuera del país de residencia. 
                 Si utiliza el Servicio con las credenciales de aplicaciones de terceros nos autoriza a recopilar su información 
                 de autenticación, como su nombre de usuario y las credenciales de acceso cifradas. El usuario habilita
                  a recopilar otro tipo de información que esté disponible en su cuenta de aplicaciones de terceros, 
                  o a través de esta como, por ejemplo, su nombre, foto de perfil, país, ciudad de residencia, dirección 
                  de correo electrónico, fecha de nacimiento, y los nombres y fotos de perfil de amigos y redes e información audiovisual.
                   Se podrá incluir voluntariamente en su perfil otras informaciones como su número de teléfono móvil y
                    proveedor de servicios de teléfono móvil, publicidades, aplicaciones de terceros, otros productos y 
                    servicios, detalle de las consultas que haya realizado con fecha hora y lugar. La negativa del usuario 
                    a proporcionar ciertos datos personales podría imposibilitar a esta aplicación o las que se vinculen con la misma, 
                    a la prestación de sus servicios, datos generados por el movimiento o datos generados por la orientación de sus sensores 
                    móviles (por ejemplo, acelerómetro o giroscopio). Se podrá tener acceso -previa autorización- de sólo lectura al estado del
                     teléfono, incluido el número de teléfono del dispositivo, la información actual de la red celular, el estado de las llamadas
                      en curso y una lista de todas las cuentas telefónicas registradas en el dispositivo. El usuario asume la responsabilidad
                       por los datos personales de terceros publicados o compartidos mediante esta aplicación y las que se vinculen con la misma,
                 y declara que tiene el consentimiento de dichos terceros para proporcionar los datos al titular.
              </p>
            </div>
            <div>
              <p className="mb-4 text-xl font-medium">
              Modalidad y lugar de guarda de los datos obtenidos.
              </p>
              <p className="text-gray-700">
              El responsable del guarda de los datos, 
              utilizará los datos de los usuarios de manera adecuada y 
              adoptará las medidas de seguridad apropiadas para impedir el acceso, 
              la revelación, alteración o destrucción no autorizados de los mismos. 
              Los datos se guardarán y recopilarán mediante ordenadores y/o herramientas informáticas,
               siguiendo procedimientos y modalidades organizativas estrictamente relacionadas con las finalidades señaladas. 
               Además del responsable de guarda, en algunos casos podrán acceder a los datos ciertas categorías de personas encargadas
                relacionadas con el funcionamiento de la aplicación y de la página (administración, ventas, marketing, departamento jurídico 
                y de administración de sistemas, etc.) o contratistas externos que presten servicios al responsable de guarda 
                (tales como proveedores externos de servicios técnicos, empresas de mensajería, "hosting providers", empresas de informática,
                 agencias de comunicación) que serán nombrados por el titular como encargados del guarda, si fuera necesario.
               Se podrá solicitar al responsable de guarda en cualquier momento una lista actualizada de dichas personas.
              </p>
            </div>
            <div>
              <p className="mb-4 text-xl font-medium">
              Información adicional sobre la recolección de datos y su guarda.
              </p>
              <p className="text-gray-700">
              Los datos personales del usuario podrán ser utilizados para la defensa
               jurídica del titular de la aplicación, o persona que guarde la misma, en juicio de cualquier 
               índole o reclamo administrativo o en las fases prejudiciales previas a un posible pleito derivado 
               del uso abusivo por parte del usuario de esta aplicación o sus vinculadas o de los servicios relacionados.
              El usuario es consciente de que el titular puede ser requerido por autoridades públicas a fin de revelar datos personales.
              </p>
            </div>
          </div>
          <div className="space-y-8">
            <div>
              <p className="mb-4 text-xl font-medium">
              Información adicional acerca de los datos personales del usuario
              </p>
              <p className="text-gray-700">
              Además de las información contenida en la política de privacidad, esta aplicación podrá proporcionar al usuario
               información contextual relativa a servicios específicos o a la recolección y guarda de datos personales.
              </p>
            </div>
            <div>
              <p className="mb-4 text-xl font-medium">
              Información no contenida en esta política de privacidad
              </p>
              <p className="text-gray-700">
              Se podrá solicitar en cualquier momento información adicional sobre la información
               recogida y el tratamiento de los datos personales al responsable
               de guarda. Encontrará la información de contacto al inicio del presente documento.
              </p>
            </div>
            <div>
              <p className="mb-4 text-xl font-medium">
              Derechos de los titulares de los datos
              </p>
              <p className="text-gray-700">
              Los titulares a los que se refieren los datos personales tienen derecho a 
              obtener en cualquier momento la confirmación de que estos han sido almacenados por el 
              responsable de guarda, a conocer su contenido y origen, a verificar su exactitud o solicitar 
              que sean completados, cancelados, actualizados o rectificados, a que sean anonimizados o a 
              que se bloqueen aquellos datos personales que están siendo tratados en contravención de las 
              leyes, así como a oponerse a su tratamiento por cualquier motivo legítimo. Las solicitudes deberán 
              remitirse al responsable de guarda utilizando los datos de contacto indicados anteriormente.
               Esta aplicación no permite solicitudes "Do Not Track". Para determinar si cualquiera de los servicios de 
              terceros que utiliza acepta solicitudes "Do Not Track", por favor lea sus políticas de privacidad.
              </p>
            </div>
            <div>
              <p className="mb-4 text-xl font-medium">
              Modificación de la política de privacidad
              </p>
              <p className="text-gray-700">
              El responsable de guarda se reserva el derecho de modificar esta política de 
              privacidad en cualquier momento notificando a los usuarios a través de esta página.
               Se recomienda enfáticamente que revisen esta página con frecuencia, 
               tomando como referencia la fecha de la última modificación indicada al final.
                En el caso de que un usuario esté en desacuerdo con alguna de las modificaciones 
                realizadas a esta política, el usuario deberá cesar en el uso de estas aplicaciones 
                y podrá solicitar al responsable de guarda que elimine sus datos personales.
                 Salvo que se indique lo contrario, la política de privacidad vigente en cada momento será de aplicación
               a todos los datos personales que el responsable de guarda haya recogido hasta entonces
              </p>
            </div>
            <div>
              <p className="mb-4 text-xl font-medium">
              Dar de Baja una cuenta
              </p>
              <p className="text-gray-700">
              Cuando te registras estas creando una cuenta única para Servicios Urbanos. Podes eliminar tu cuenta cuando desees, enviando un mail a 
              hola@su-sa.com.ar.
              Sin embargo, ten en cuenta que al hacerlo ya no podrás utilizar el servicio, perdiendo la información que se encuentra a disposición, entre otras cosas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
        </>
    )
}