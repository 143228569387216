import React from 'react'
import { Disclosure } from '@headlessui/react'

//CSS
import susa from '../../assets/icons/susa-logo.png'

import {  MenuIcon, XIcon } from '@heroicons/react/outline'

//Taiwilnd

const navigation = [
  // { name: 'Inicio', href: '/inicio', current: true },
  { name: 'Sube Misionero', href: '/sube', current: true },
  { name: 'Billetera Electrónica', href: '/billetera', current: true },
  { name: 'BondiYa!', href: '/bondi', current: true },
  { name: 'Terminales', href: '/terminales', current: true },
  { name: 'Atención al cliente', href: '/atencion', current: false },
  { name: 'Contacto', href: '/contacto', current: false },

]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Navbar = () => {
  return (
    <Disclosure as="nav" className="bg-white-500">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-500 hover:bg-white-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-400">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                  <a href="/inicio">
                  <img
                    className=" lg:block h-8 w-28 h-auto"
                    src={susa}
                    alt="susa"
                  />
                  </a>
                </div>
                <div className=" sm:ml-6">
                  
                </div>
              </div>
              <div className="hidden sm:block absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:ml-6 sm:pr-0">
              <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-white-700 text-gray-500 hover:bg-yellow-400 hover:text-white' : 'text-gray-500 hover:bg-yellow-400 hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-white-700 text-gray-500 hover:bg-yellow-400 hover:text-white' : 'text-gray-500 hover:bg-yellow-400 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}