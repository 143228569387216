import React from "react";

export const PaymentsScreen = () => {
  return (
    <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <span className="relative"></span>
            </span>{" "}
            Cobro con BE
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            También podés cobrar productos o servicios desde tu billetera
            electrónica SUSA.
          </p>
        </div>
        <div className="grid max-w-screen-lg mx-auto space-y-6 lg:grid-cols-2 lg:space-y-0 lg:divide-x">
          <div className="space-y-6 sm:px-16">
            <div className="flex flex-col max-w-md sm:flex-row">
              <div className="mb-4 mr-4">
                <div className="flex flex-col sm:flex-row">
                  <div className="sm:mr-4">
                    <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                      <svg
                        className="w-12 h-12 text-deep-purple-accent-400"
                        stroke="currentColor"
                        viewBox="0 0 52 52"
                      >
                        <polygon
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fill="none"
                          points="29 13 14 29 25 29 23 39 38 23 27 23"
                        />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <h6 className="mb-3 text-xl font-bold leading-5">Cómo</h6>
                    <p className="mb-3 text-sm text-gray-900">
                      Desde la opción Operaciones con QR de tu billetera
                    </p>
                    <p className="mb-3  font-bold text-sm text-gray-900">
                      Procedimiento:
                    </p>
                    <p className="mb-3  text-sm text-gray-900">
                      En tu Billetera vas al Botón Central y verás una pantalla
                      azul con 4 opciones.
                    </p>
                    <ul className="mb-4 -ml-1 space-y-2">
                      <li className="flex items-start">
                        <span className="mr-1">
                          <svg
                            className="w-5 h-5 mt-px text-deep-purple-accent-400"
                            stroke="currentColor"
                            viewBox="0 0 52 52"
                          >
                            <polygon
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill="none"
                              points="29 13 14 29 25 29 23 39 38 23 27 23"
                            />
                          </svg>
                        </span>
                        Selecciona Operaciones con QR
                      </li>
                      <li className="flex items-start">
                        <span className="mr-1">
                          <svg
                            className="w-5 h-5 mt-px text-deep-purple-accent-400"
                            stroke="currentColor"
                            viewBox="0 0 52 52"
                          >
                            <polygon
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill="none"
                              points="29 13 14 29 25 29 23 39 38 23 27 23"
                            />
                          </svg>
                        </span>
                        En la pantalla encontrarás los cargos del Servicio,
                        seleccionar Continuar
                      </li>
                      <li className="flex items-start">
                        <span className="mr-1">
                          <svg
                            className="w-5 h-5 mt-px text-deep-purple-accent-400"
                            stroke="currentColor"
                            viewBox="0 0 52 52"
                          >
                            <polygon
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill="none"
                              points="29 13 14 29 25 29 23 39 38 23 27 23"
                            />
                          </svg>
                        </span>
                        Ingresa el monto o cobrar y la fecha naranja
                      </li>
                      <li className="flex items-start">
                        <span className="mr-1">
                          <svg
                            className="w-5 h-5 mt-px text-deep-purple-accent-400"
                            stroke="currentColor"
                            viewBox="0 0 52 52"
                          >
                            <polygon
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill="none"
                              points="29 13 14 29 25 29 23 39 38 23 27 23"
                            />
                          </svg>
                        </span>
                        Aparecerá un código QR que el tu cliente deberá escanear
                        con su billetera electrónica SUSA
                      </li>
                      <li className="flex items-start">
                        <span className="mr-1">
                          <svg
                            className="w-5 h-5 mt-px text-deep-purple-accent-400"
                            stroke="currentColor"
                            viewBox="0 0 52 52"
                          >
                            <polygon
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill="none"
                              points="29 13 14 29 25 29 23 39 38 23 27 23"
                            />
                          </svg>
                        </span>
                        Presionar Finalizar
                      </li>
                      <li className="flex items-start">
                        <span className="mr-1">
                          <svg
                            className="w-5 h-5 mt-px text-deep-purple-accent-400"
                            stroke="currentColor"
                            viewBox="0 0 52 52"
                          >
                            <polygon
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill="none"
                              points="29 13 14 29 25 29 23 39 38 23 27 23"
                            />
                          </svg>
                        </span>
                        En la pantalla encontrarás los cargos del Servicio,
                        seleccionar "Continuar".
                        <br/>
                        El dinero ingresado a tu billetera electrónica podrás
                        retirarlo en efectivo en las terminales de transferencia y
                        en los comercios adheridos o utilizarlo desde la billetera
                        para abonar el transporte público y productos o servicios.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col max-w-md sm:flex-row">
              <div className="mb-4 mr-4"></div>
            </div>
            <div className="flex flex-col max-w-md sm:flex-row">
              <div className="mb-4 mr-4"></div>
            </div>
          </div>
          <div className="space-y-6 sm:px-16">
            <div className="flex flex-col max-w-md sm:flex-row">
              <div>
                <div className="mb-4 mr-4">
                <div className="flex flex-col sm:flex-row">
                  <div className="sm:mr-4">
                    <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                      <svg
                        className="w-12 h-12 text-deep-purple-accent-400"
                        stroke="currentColor"
                        viewBox="0 0 52 52"
                      >
                        <polygon
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fill="none"
                          points="29 13 14 29 25 29 23 39 38 23 27 23"
                        />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <h6 className="mb-3 text-xl font-bold leading-5">Preguntas Frecuentes</h6>
                    <ul className="mb-4 -ml-1 space-y-2">
                      <li className="flex items-start">
                        <span className="mr-1">
                          <svg
                            className="w-5 h-5 mt-px text-deep-purple-accent-400"
                            stroke="currentColor"
                            viewBox="0 0 52 52"
                          >
                            <polygon
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill="none"
                              points="29 13 14 29 25 29 23 39 38 23 27 23"
                            />
                          </svg>
                        </span>
                        Selecciona Operaciones con QR
                      </li>
                      <li className="flex items-start">
                        <span className="mr-1">
                          <svg
                            className="w-5 h-5 mt-px text-deep-purple-accent-400"
                            stroke="currentColor"
                            viewBox="0 0 52 52"
                          >
                            <polygon
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill="none"
                              points="29 13 14 29 25 29 23 39 38 23 27 23"
                            />
                          </svg>
                        </span>
                        En la pantalla encontrarás los cargos del Servicio,
                        seleccionar Continuar
                      </li>
                    </ul>
                    <a
                      href="/"
                      aria-label=""
                      className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                    >
                    
                    </a>
                  </div>
                  
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    
    </>
  );
};
