import React from "react";

import subethree from "../assets/img/tarjeta-nena.jpeg";
import sube from "../assets/img/sube-uno.jpeg";
import sube2 from "../assets/img/sube-clientes.jpeg";
import sube3 from "../assets/img/sube-dos.jpeg";

export const SubeScreen = () => {
  return (
    <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-teal-accent-400">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
            </svg>
            </div>
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Sube Misionero
              </h2>
              <p className="text-base text-gray-700 md:text-md">
                La tarjeta Sube Misionero es el medio de pago electrónico para
                abonar el transporte público en vigencia desde el año 2014.
                Permite a los usuarios viajar en ómnibus en el Sistema Integrado
                de Transporte del área metropolitana de Posadas, recargando
                efectivo en una única tarjeta. La cual, ante el simple
                acercamiento al validador, descuenta el costo del viaje,
                evitando la necesidad de contar con dinero en efectivo para
                movilizarse.
              </p>
              <p className="text-base text-gray-700 md:text-md">
                Su implementación fue ordenada por el Intendente de la Ciudad de
                Posadas a través del Artículo 2º del Decreto Nº 254/2013.
              </p>
            </div>
            <div></div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <div className="flex flex-col items-end px-3">
              <img
                className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                src={sube}
                alt=""
              />
              <img
                className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                src={sube2}
                alt=""
              />
            </div>
            <div className="px-3">
              <img
                className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                src={sube3}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              beneficios
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 mb-4 -ml-20 text-red-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="fdca20a0-aeb4-4caf-ba1b-4351eee42363"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#fdca20a0-aeb4-4caf-ba1b-4351eee42363)"
                  width="52"
                  height="24"
                />
              </svg>
            </span>{" "}
            Beneficios Sube Misionero
          </h2>
          <p className="text-base text-gray-700 md:text-lg"></p>
        </div>
        <div className="grid max-w-md gap-8 row-gap-10 sm:mx-auto lg:max-w-full lg:grid-cols-3">
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-4">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-red-50">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              </div>
            </div>
            <div>
              <h6 className="mb-2 font-semibold leading-5">TIEMPO</h6>
              <p className="mb-3 text-sm text-gray-900">
                Reducción de los tiempos de viaje, debido a la disminución del
                tiempo insumido en la adquisición del boleto de cada viaje
                realizado.
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-4">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-red-50">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01" />
              </svg>
              </div>
            </div>
            <div>
              <h6 className="mb-2 font-semibold leading-5">SEGURIDAD</h6>
              <p className="mb-3 text-sm text-gray-900">
                Aumenta la seguridad del usuario eliminando el uso de efectivo,
                brindando una mayor seguridad al disminuir el movimiento de
                efectivo.
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-4">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-red-50">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
              </svg>
              </div>
            </div>
            <div>
              <h6 className="mb-2 font-semibold leading-5">CONTROL</h6>
              <p className="mb-3 text-sm text-gray-900">
                Permite mejorar la calidad y fiscalización de los servicios a
                través del aprovechamiento de la información estadística que el
                sistema del boleto electrónico brinda.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col lg:flex-row">
          <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-5">
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Trámites
            </h2>
          </div>
          <div className="lg:w-1/2">
            <p className="text-base text-gray-700">
              Las tarjetas nominadas son personales e intransferibles y llevan
              impreso el nombre y apellido del titular y número identificatorio
              de la tarjeta, para lo cual el interesado debe registrarse en los
              centros de atención habilitados.
            </p>
            <p className="mt-2 text-base text-gray-700">
              La nominación de las tarjetas permite al usuario acceder a los
              beneficios y, en caso de corresponder, realizar gestiones por
              robo, extravío, pérdida o rotura; así como recuperar el saldo
              remanente antes tales circunstancias, mediante una solicitud de
              bloqueo que deberá realizar en el Centro de Atención y que luego
              será reintegrado a su nueva tarjeta SUBE Misionero.
            </p>
            <p className="mt-2 text-base text-gray-700">
              Los tramites son personales y en todos los casos deberá
              presentarse el titular con su correspondiente D.N.I Original. La
              primera tarjeta se entrega en forma gratuita.
            </p>
          </div>
        </div>
      </div>

      <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 z-0 w-full h-full bg-gray-100 lg:w-3/4" />
        </div>
        <div className="relative">
          <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
            <div className="grid gap-12 row-gap-5 md:grid-cols-2">
              <div className="relative">
               
                <div className="relative">
                  <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-teal-accent-400">
                    <svg
                      className="w-8 h-8 text-teal-900"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </div>
                  <h6 className="mb-2 font-semibold leading-5">
                    Beneficio Boleto Estudiantil Gratuito
                  </h6>
                  <p className="text-sm text-gray-900">REQUISITOS:</p>
                  <p className="text-sm text-gray-900">
                    Constancia de Inscripción BEEG.
                  </p>
                  <p className="text-sm text-gray-900">DNI original y copia.</p>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-teal-accent-400">
                  <svg
                    className="w-8 h-8 text-teal-900"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Beneficio por Discapacidad
                </h6>
                <p className="text-sm text-gray-900">REQUISITOS:</p>
                <p>
                  Certificado Único de Discapacidad ("CUD" Original y Copia).
                </p>
                <p>DNI Original.</p>
              </div>
              <div>
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-teal-accent-400">
                  <svg
                    className="w-8 h-8 text-teal-900"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Beneficio de Mayores de 70 años
                </h6>
                <p className="text-sm text-gray-900">REQUISITOS:</p>
                <p>-Usuarios con Domicilio en la Ciudad de Posadas</p>
                <p>DNI Original y Copia.</p>
                <p>Tener 70 Años o más.</p>
                <p>-Usuarios con Domicilio en la Ciudad de Garupá</p>
                <p> DNI Original y Copia.</p>
                <p> Mujeres: Tener 60 Años</p>
                <p> Varones: Tener 65 Años</p>
                <p>
                  Carnet de la Tercera Edad emitido por la Municipalidad de
                  Garupá (Original y Copia).
                </p>
              </div>
              <div>
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-teal-accent-400">
                  <svg
                    className="w-8 h-8 text-teal-900"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Beneficio Ex-Combatiente de Malvinas
                </h6>
                <p className="text-sm text-gray-900">REQUISITOS:</p>
                <p className="text-sm text-gray-900">
                  Certificado de Ex-Combatiente (Original y Copia).
                </p>
                <p className="text-sm text-gray-900">DNI Original.</p>
              </div>
            </div>
            <div>
              <img
                className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                src={subethree}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
