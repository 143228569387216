import React, { Component } from "react";
import bondiya2 from "../assets/img/bondiya2.png";

export const bondiScreen = () => {
  return (
    <div>
      <article>
        <div className="relative flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
          <div className="flex flex-col items-start w-full max-w-xl px-4 mx-auto lg:px-8 lg:max-w-screen-xl">
            <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
              <div className="max-w-xl mb-6">
                <div>
                  <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-yellow-400 uppercase rounded-full bg-teal-accent-400">
                    Aplicación
                  </p>
                </div>
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                  BondiYa!
                </h2>
                <p className="text-base text-gray-700 md:text-lg">
                  BondiYA! es la aplicación de información del Sistema Integrado
                  de Transporte, pensada para hacerte la vida más fácil.
                </p>
              </div>
            </div>
          </div>
          <div className="inset-y-0 right-0 w-full max-w-xl px-4 mx-auto lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0">
            <img
              className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none sm:h-96 lg:h-full"
              src={bondiya2}
              alt=""
            />
          </div>
        </div>
      </article>

      <article>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-yellow-500 uppercase rounded-full bg-teal-accent-400">
                Informa
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-yellow-400 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="07690130-d013-42bc-83f4-90de7ac68f76"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#07690130-d013-42bc-83f4-90de7ac68f76)"
                    width="52"
                    height="24"
                  />
                </svg>
              </span>{" "}
              Información
            </h2>
          </div>
          <div className="grid max-w-screen-lg mx-auto space-y-6 lg:grid-cols-2 lg:space-y-0 lg:divide-x">
            <div className="space-y-6 sm:px-16">
              <div className="flex flex-col max-w-md sm:flex-row">
                <div className="mb-4 mr-4">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-yellow-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <h6 className="mb-3 text-xl font-bold leading-5">
                    {" "}
                    Las paradas cercanas a tu ubicación
                  </h6>
                  <p className="text-sm text-gray-900">
                    En un radio de 500 mts.
                  </p>
                </div>
              </div>
              <div className="flex flex-col max-w-md sm:flex-row">
                <div className="mb-4 mr-4">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-yellow-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <h6 className="mb-3 text-xl font-bold leading-5">
                    Las líneas que llegan a cada parada
                  </h6>
                </div>
              </div>
              <div className="flex flex-col max-w-md sm:flex-row">
                <div className="mb-4 mr-4">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-yellow-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <h6 className="mb-3 text-xl font-bold leading-5">
                    Las líneas que están próximas a arribar
                  </h6>
                  <p className="text-sm text-gray-900">
                    Detallando el número de línea, el destino y tiempo
                    aproximado de arribo.
                  </p>
                </div>
              </div>
            </div>
            <div className="space-y-6 sm:px-16">
              <div className="flex flex-col max-w-md sm:flex-row">
                <div className="mb-4 mr-4">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-yellow-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <h6 className="mb-3 text-xl font-bold leading-5">
                    Las líneas están arribando
                  </h6>
                  <p className="text-sm text-gray-900">
                    A las Terminales de Transferencia y en cuánto tiempo.
                  </p>
                </div>
              </div>
              <div className="flex flex-col max-w-md sm:flex-row">
                <div className="mb-4 mr-4">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-yellow-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <h6 className="mb-3 text-xl font-bold leading-5">
                    Seleccionando una línea muestra las paradas de esa línea
                  </h6>
                  <p className="text-sm text-gray-900">
                    De dos maneras: marcadas en un mapa o en forma de lista.
                  </p>
                </div>
              </div>
              <div className="flex flex-col max-w-md sm:flex-row">
                <div className="mb-4 mr-4">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-yellow-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <h6 className="mb-3 text-xl font-bold leading-5">
                    Opción MAPA
                  </h6>
                  <p className="text-sm text-gray-900">
                    Muestra todas las paradas cercanas a tu ubicación y al hacer
                    clic sobre el ícono de una de esas paradas podrás ver los
                    próximos arribos. Detalla todas las paradas de cada línea.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
       <article>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-lg sm:text-center sm:mx-auto">
          <a
            href="/"
            aria-label="Go Home"
            title="Logo"
            className="inline-block mb-4"
          >
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-green-300">
              <svg
                className="w-10 h-10 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
          </a>
          <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            Descarga BondiYA!
          </h2>

          <hr className="my-8 border-gray-300" />
          <div className="flex items-center mb-3 sm:justify-center">
            <a
              href="https://apps.apple.com/ar/app/bondiya/id1470497991"
              className="mr-3 transition duration-300 hover:shadow-lg"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/1000px-Download_on_the_App_Store_Badge.svg.png"
                className="object-cover object-top w-32 mx-auto"
                alt=""
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.serviciosurbanos.bondiya&hl=es_AR&gl=US"
              className="transition duration-300 hover:shadow-lg"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1000px-Google_Play_Store_badge_EN.svg.png"
                className="object-cover object-top w-32 mx-auto"
                alt=""
              />
            </a>
          </div>
          <p className="max-w-xs text-xs text-gray-600 sm:text-sm sm:max-w-sm sm:mx-auto">
            Aplicación para mayores de 12 años.
          </p>
        </div>
    </div>
   </article>
   </div>
   
  );
}
