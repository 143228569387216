import React from 'react';
import ReactDOM from 'react-dom';
import { SusaApp } from './SusaApp';
import './index.css';


ReactDOM.render(
  <SusaApp />,
  document.getElementById('root')
);

