import React from "react";



export const BlogScreen = () => {


    return (
        <>
        <div className="container mx-auto pt-16">
            <div className="mb-12 xl:w-full w-11/12 mx-auto">
            </div>
            <div className="relative bg-center bg-cover bg-no-repeat mb-40 w-11/12 mx-auto xl:w-full h-64 rounded-md">
                <img src="https://www.subemisionero.com.ar/images/slider/slider-01.jpg"  className="h-full w-full object-cover overflow-hidden absolute rounded-xl" />
                <div className="absolute bottom-0 w-11/12 ml-2 xl:mx-0 lg:mx-0 xl:w-6/12 lg:w-6/12 xl:ml-12 lg:ml-12 rounded-md lg:-mb-56 sm:-mb-48 -mb-64 xl:-mb-24">
                    <div className="w-full py-5 px-6 bg-white shadow rounded">
                        <p className="text-sm text-yellow-400 pb-1">Actualizando</p>
                        <p className="text-xl text-gray-800 font-bold pb-2">Esta página se encuentra en construción.</p>
                        <p className="text-base text-gray-600"></p>
                    </div>
                </div>
            </div>
            <div className="pt-32 md:pt-12 xl:pt-0 xl:w-full w-11/12 mx-auto border-b .border-gray-300">
                
                <div className="xl:flex lg:flex sm:flex-1 md:flex flex-wrap justify-between">
                    
                </div>
            </div>
        </div>
      
            
       </> 
    );
}

