import React from "react";
import asesor from "../assets/img/199052608_3842364315875445_6965214401536846533_n.png";
import service from "../assets/img/179504421_3719883811456830_1200217947050822886_n.jpg";
import custom from "../assets/img/atenciónAlPublico-2.jpeg";
import terminal from "../assets/img/UNAM3 2.jpeg";
import posadas from '../assets/img/atencion.jpeg';

export const CustomersScreen = () => {

  const people = [
    {
      name: 'Cyber EL Angel',
      title: 'Calle 184 N° 4320',
      department: 'San Isidro',
      role: 'Posadas',
     
    
    },
    // More people...
    {
      name: 'Agencia IPLyC N°101',
      title: 'Cordoba N° 1838',
      department: 'Microcentro',
      role: 'Posadas',
      
     
    },
  ]
  return (
    <>
   <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
            Centro de atención al cliente
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-red-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="2feffae2-9edf-414e-ab8c-f0e6396a0fc1"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#2feffae2-9edf-414e-ab8c-f0e6396a0fc1)"
                width="52"
                height="24"
              />
            </svg>
          
          </span>{' '}
          Atención al cliente
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
         Servicios Urbanos S.A. cuenta con dos locales para la atención a clientes
        </p>
        <p className="mt-2 text-base text-gray-700 md:text-lg">
         Terminal de Transferencia UNaM y Posadas Plaza Shopping. Primer Piso
        </p>
      </div>
      <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
        <div className="grid grid-cols-2 gap-5">
          <img loading="lazy"
            className="object-cover w-full h-56 col-span-2 rounded shadow-lg"
            src={custom}
            alt=""
          />
          <img loading="lazy"
            className="object-cover w-full h-48 rounded shadow-lg"
            src={asesor}
            alt=""
          />
          <img loading="lazy"
            className="object-cover w-full h-48 rounded shadow-lg"
            src={service}
            alt=""
          />
        </div>
        <div className="flex flex-col justify-center">
          <div className="pb-4 mb-4 border-b">
            <h6 className="mb-2 font-semibold leading-5">
             Registro de Usuario
            </h6>
            <p className="text-sm text-gray-900">
             
            </p>
          </div>
          <div className="pb-4 mb-4 border-b">
            <h6 className="mb-2 font-semibold leading-5">
              Denuncia por Extravío/Robo
            </h6>
            <p className="text-sm text-gray-900">
             
            </p>
          </div>
          <div>
            <h6 className="mb-2 font-semibold leading-5">
             Consulta de movimientos
            </h6>
            <p className="text-sm text-gray-900">
              
            </p>
          </div>
        </div>
      </div>
    </div>
     
     
    
   

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Terminal de Transferencia UNaM
              </h2>
              <p className="text-base text-yellow-500 md:text-lg">
                Ruta N° 12 KM 7 1/2.
              </p>
              <p className="mt-2 text-base text-gray-700 md:text-lg">
                Horario de Atención: Lunes a viernes: 08:30hs - 13:00hs y
                14:00hs - 17:30hs{" "}
              </p>
              <p className="mt-2 text-base text-gray-700 md:text-lg">
                Sábados: 08:00hs - 12:00hs
              </p>
            </div>
            <div className="grid space-y-3 sm:gap-2 sm:grid-cols-2 sm:space-y-0">
              <ul className="space-y-3">
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      className="w-5 h-5 mt-px text-deep-purple-accent-400"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </span>
                  Recarga de Saldo
                </li>
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      className="w-5 h-5 mt-px text-deep-purple-accent-400"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </span>
                  Registración de Usuario
                </li>
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      className="w-5 h-5 mt-px text-deep-purple-accent-400"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </span>
                  Consulta de movimientos
                </li>
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      className="w-5 h-5 mt-px text-deep-purple-accent-400"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </span>
                  Denuncia por Extravío / Robo
                </li>
              </ul>
              <ul className="space-y-3">
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      className="w-5 h-5 mt-px text-deep-purple-accent-400"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </span>
                  Acceso/Actualización de Beneficios
                </li>
              </ul>
            </div>
          </div>
          <div>
            <img loading="lazy"
              className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
              src={terminal}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
          <div>
            <img loading="lazy"
              className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
              src={posadas}
              alt=""
            />
          </div>
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Posadas Plaza Shopping
              </h2>
              <p className="text-base text-yellow-500 md:text-lg">
                Local N° 42. San Lorenzo y Bolívar
              </p>
              <p className=" mt-2 text-base text-gray-700 md:text-lg">
                Horario de Atención: Lunes a sábado: 09:00hs - 21:00hs
              </p>
            </div>
            <div className="grid space-y-3 sm:gap-2 sm:grid-cols-2 sm:space-y-0">
              <ul className="space-y-3">
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      className="w-5 h-5 mt-px text-deep-purple-accent-400"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </span>
                  Registración de Usuario

                </li>
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      className="w-5 h-5 mt-px text-deep-purple-accent-400"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </span>
                  Denuncia por Extravío / Robo

                </li>
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      className="w-5 h-5 mt-px text-deep-purple-accent-400"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </span>
                  Acceso/Actualización de Beneficios

                </li>
              </ul>
              <ul className="space-y-3">
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      className="w-5 h-5 mt-px text-deep-purple-accent-400"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </span>
                  Recarga de Saldo

                </li>
              </ul>
              <ul className="space-y-3">
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      className="w-5 h-5 mt-px text-deep-purple-accent-400"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </span>
                  Consulta de movimientos


                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> 
      
      <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 sm:mx-auto">
        <h2 className="font-sans text-3xl font-bold leading-tight tracking-tight text-gray-900 sm:text-4xl sm:text-center">
          Puntos de Recarga{' '}
          
        </h2>
      </div>
    </div>
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <div class="embed-container"><iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title="Mapa web SUBEMisionero" src="//www.arcgis.com/apps/Embed/index.html?webmap=ffd605cef0d94136a27bd8f7fd991024&extent=-56.0732,-27.5074,-55.7141,-27.3407&home=true&zoom=true&previewImage=false&scale=true&search=true&searchextent=true&disable_scroll=true&theme=light"></iframe></div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
