import React, { useState } from 'react'

//Libs
import { Swiper, SwiperSlide } from "swiper/react";
import { BannerSlider } from '../components/banner/banner'

import bondiya from '../assets/img/bondiya1.png'

//IMG
import banner from '../assets/img/Group-3.png'
import sube from '../assets/img/179504421_3719883811456830_1200217947050822886_n.jpg'
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
//CSS
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../index.css';

//Banner
import {BannerTwo} from '../components/banner/BannerTwo'
import {BannerThree} from '../components/banner/BannerThree'
// import Swiper core and required modules
import SwiperCore, {
    Parallax,Pagination,Navigation, Autoplay, EffectFade
} from 'swiper/core';

    // install Swiper modules
    SwiperCore.use([ EffectFade,Parallax,Pagination,Navigation, Autoplay]);


export const DashboardScreen = () => {
    
    const [change, setChange] = useState(false);
    return (
        <div>
            <Swiper pagination={{"clickable": true}} style={{'--swiper-navigation-color': 'orange','--swiper-pagination-color': 'orange',height:500}} loop={true} autoplay={{delay: 3500,disableOnInteraction: false}} data-swiper-autoplay="3200" className="mySwiper">
                <SwiperSlide>  <BannerThree /> </SwiperSlide>
                    <SwiperSlide> <BannerTwo /> </SwiperSlide>
                    <SwiperSlide> <BannerSlider /> </SwiperSlide>
            </Swiper>
        <div>
            {/* Billetera */}
            <a
                href="https://wa.me/+5493765022276"
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fa fa-whatsapp whatsapp-icon"></i>
            </a>
            <div className="md:mx-auto md:container px-4">
                <div className="pt-10 md:pt-40">
                    <div className="container mx-auto">
                        <div className="flex flex-wrap items-center pb-12">
                            <div className="md:w-1/2 lg:w-2/3 w-full xl:pr-18 md:pr-6">
                                <div className="py-2 text-color">
                                    <h1 className="text-2xl lg:text-3xl md:leading-snug tracking-tighter c font-black">Billetera Electónica</h1>
                                    <h2 className="text-md lg:text-md lg:leading-7 md:leading-8 f-f-r py-4 md:py-8">
                                    La nueva Billetera Electrónica permite a los usuarios movilizarse sin llevar dinero 
                                    en efectivo, autogestionar la recarga de crédito para viajar en transporte público, 
                                    evitar el trámite presencial de registro. Por todo esto colabora con los protocolos de seguridad sanitaria y 
                                    contribuye a la seguridad vial al eximir al conductor de la gestión de cobro del pasaje.
                                    </h2>
                                    <div className="flex items-center cursor-pointer pb-4 md:pb-0">
                                        <a href="/billetera">

                                        <h3 className="f-f-r text-lg lg:text-sm font-semibold underline text-yellow-400">Conoce más</h3>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:w-1/3 md:w-1/2 w-full relative h-96 flex items-end justify-center">
                                <img className="absolute" src={banner} alt />
                            </div>
                            <div className="py-12  bg-white mt-8">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="lg:text-center">
                                <h2 className="text-base text-yellow-400 font-semibold tracking-wide uppercase">Billetera Electrónica </h2>
                                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Hace más con tu billetera
                                </p>
                                <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                                Al igual que la tarjeta Sube Misionero, es personal e Intransferible.</p>
                                </div>

                                <div className="mt-10">
                                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                                    <div className="relative">
                                    <dt>
                                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                                        </svg>
                                        </div>
                                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Funcionamiento</p>
                                        </dt>
                                            <dd className="mt-2 ml-16 text-base text-gray-500">
                                                El usuario descarga la aplicación en su celular, transfiere crédito a su billetera electrónica desde su cuenta bancaria y puede comenzar a utilizarla.
                                            </dd>
                                        </div>
                                    <div className="relative">
                                    <dt>
                                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                        </svg>
                                        </div>
                                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Transferencias instantaneas </p>
                                    </dt>
                                    <dd className="mt-2 ml-16 text-base text-gray-500">
                                    Ya sea que necesites pasarle saldo a alguien para pagar el transporte o simplemente transferir dinero, podes hacerlo de manera instantanea a su CBU/CVU o Alias.
                                
                                    </dd>
                                    </div>

                                    <div className="relative">
                                    <dt>
                                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                        </svg>
                                        </div>
                                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">En el ómnibus</p>
                                    </dt>
                                    <dd className="mt-2 ml-16 text-base text-gray-500">
                                    En cada coche se ubican 3 validadores.
                                    Al subir el usuario debe iniciar su viaje pasando el código QR 
                                    de la billetera por el validador que se encuentra detrás del chofer. 
                                    Antes de descender, debe cerrar el viaje pasando nuevamente el código 
                                    QR de la aplicación por alguno de los dos validadores que se encuentran
                                     ubicados en las puertas de atrás.  De esta manera se abonan sólo los 
                                     recorridos que correspondan.
                                    </dd>
                                    </div>

                                    <div className="relative">
                                    <dt>
                                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                                        </svg>
                                        </div>
                                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Notificaciones</p>
                                    </dt>
                                    <dd className="mt-2 ml-16 text-base text-gray-500">
                                        Cada vez que pagues el transporte o te depositen dinero, tendras una notificación para poder estar al tanto de tus movimientos. Simple, claro e instantaneo.
                                    </dd>
                                    </div>
                                </dl>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pb-32 pt-16">
                    <div className="mx-auto">
                        <div className="flex flex-wrap flex-row-reverse items-center">
                            <div className="md:w-1/2 lg:w-2/3 w-full lg:pl-20 md:pl-10 sm:pl-0 pl-0">
                                <div className="py-2 text-color">
                                    <h1 className="text-2xl lg:text-3xl tracking-tighter md:leading-snug f-f-l font-black">Sube Misionero</h1>
                                    <h2 className="text-md lg:text-md lg:leading-7 md:leading-8 f-f-r py-4 md:py-8">
                                        La tarjeta Sube Misionero es el medio de pago electrónico para abonar 
                                        el transporte público en vigencia desde el año 2014. Permite a los usuarios 
                                        viajar en ómnibus en el Sistema Integrado de Transporte del área metropolitana de Posadas, 
                                        recargando efectivo en una única targeta. La cual, ante el simple acercamiento al validador,
                                        descuenta el costo del viaja,
                                        evitando la necesidad de contar con dinero en efectivo para movilizarse.
                                    </h2>
                                    
                                    <div className="flex items-center cursor-pointer pb-4 md:pb-0">
                                        <a href="/sube">
                                        <h3 className="f-f-r text-lg lg:text-sm font-semibold  text-yellow-400">Conoce más</h3>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:w-1/3 md:w-1/2 w-full relative h-96 flex items-end justify-center">
                                <img className="absolute w-full h-full inset-0 object-cover object-center rounded-md" src={sube} alt />
                                <div className="relative z-10 p-4 bg-white shadow rounded mx-auto w-9/12 -mb-20">
                                    <h4 className="text-gray-800 font-normal text-md leading-4 tracking-normal mb-2">Podes usar tú tarjeta Sube Misionero</h4>
                                    <div className="mt-4 flex items-center">
                                        <p className="ml-1 text-sm text-gray-800 leading-2 tracking-normal font-normal">La tarjeta Sube Misionero sigue en plena vigencia. Desde este año van a existir 3 formas de pago: Tarjeta Sube Misionero, Billetera Electrónica y pago en efectivo.</p>
                                    </div>
                                    <button className="relative focus:outline-none mt-4 py-2 pr-10 bg-yellow-500 text-white tracking-normal text-xs pl-3 cursor-pointer hover:opacity-90">
                                            Más info
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SUBE */}
            <div>
                <div className="container mx-auto pt-16">
                    <h6 className="text-gray-800 font-extrabold xl:text-3xl text-3xl mb-4 text-center md:text-center lg:text-center">BondiYa!</h6>
                    <p className="text-xl text-center md:text-center xl:w-2/3 lg:w-2/3 w-11/12 mx-auto text-gray-600 mb-12">BondiYa! es la app del Sistema de Transporte, pensada para hacerte la vida más fácil.</p>
                </div>
                <div className="w-full bg-white-100 py-16">
                    <div className="container relative mx-auto container-fluid">
                        <div className="absolute -mt-16 top-0">
                            <svg width={151} height={104} xmlns="http://www.w3.org/2000/svg">
                                <g fill="#FFA03C" fillRule="evenodd">
                                    <rect x=".88" width={3} height={3} rx="1.5" />
                                    <rect x="17.88" width={3} height={3} rx="1.5" />
                                    <rect x="32.88" width={3} height={3} rx="1.5" />
                                    <rect x="49.88" width={3} height={3} rx="1.5" />
                                    <rect x="65.88" width={3} height={3} rx="1.5" />
                                    <rect x="82.88" width={3} height={3} rx="1.5" />
                                    <rect x="98.88" width={3} height={3} rx="1.5" />
                                    <rect x="115.88" width={3} height={3} rx="1.5" />
                                    <rect x="131.88" width={3} height={3} rx="1.5" />
                                    <rect x="147.88" width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={101} width={3} height={3} rx="1.5" />
                                </g>
                            </svg>
                        </div>
                        <div className="absolute -mb-16 bottom-0 right-0">
                            <svg width={151} height={104} xmlns="http://www.w3.org/2000/svg">
                                <g fill="#FFA03C" fillRule="evenodd">
                                    <rect x=".88" width={3} height={3} rx="1.5" />
                                    <rect x="17.88" width={3} height={3} rx="1.5" />
                                    <rect x="32.88" width={3} height={3} rx="1.5" />
                                    <rect x="49.88" width={3} height={3} rx="1.5" />
                                    <rect x="65.88" width={3} height={3} rx="1.5" />
                                    <rect x="82.88" width={3} height={3} rx="1.5" />
                                    <rect x="98.88" width={3} height={3} rx="1.5" />
                                    <rect x="115.88" width={3} height={3} rx="1.5" />
                                    <rect x="131.88" width={3} height={3} rx="1.5" />
                                    <rect x="147.88" width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={13} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={25} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={38} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={51} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={63} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={76} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={89} width={3} height={3} rx="1.5" />
                                    <rect x=".88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="17.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="32.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="49.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="65.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="82.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="98.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="115.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="131.88" y={101} width={3} height={3} rx="1.5" />
                                    <rect x="147.88" y={101} width={3} height={3} rx="1.5" />
                                </g>
                            </svg>
                        </div>
                        <section id="carousel">
                            <figure className={change ? "hidden" : "block"}>
                                {/* Prueb */}
                                <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                                    <div className="md:flex">
                                        <div className="md:flex-shrink-0">
                                            <img className="h-48 w-full object-cover md:h-full md:w-48" src={bondiya} alt="Man looking at item at a store" />
                                        </div>
                                        <div className="p-8">
                                            <div className="uppercase tracking-wide text-sm text-yellow-500 font-semibold">Información al instante</div>
                                            <p className="mt-2 text-gray-500">Las paradas más cercanas a tú ubicación, las líneas que llegan a esas paradas, el tiempo de arribo de cada una de esas líneas y más.</p>
                                            <a href="/bondi" className="block mt-1 text-lg leading-tight font-medium text-yellow-500 hover:underline underline">conoce más </a>
                                        </div>
                                    </div>
                                </div>

                            </figure>

                            <figure className={change ? "block" : "hidden"}>
                                <div className="w-11/12 mx-auto bg-white py-10 sm:px-16 px-10 relative">
                                    <div className="flex items-center justify-between pb-8">
                                        <div className="xl:flex md:flex lg:flex sm:flex items-center">
                                            <div className="h-20 w-20">
                                                <img src="https://cdn.tuk.dev/assets/templates/Project+Management/pm_15.png" alt className="rounded-full object-cover h-full w-full shadow" />
                                            </div>
                                            <div className="xl:pl-6 lg:pl-6 md:pl-6 sm:pl-6 sm:pt-0 pt-4">
                                                <p className="text-xl text-gray-600 font-bold">Richard Stark</p>
                                                <p className="sm:text-xl text-sm text-gray-600">UX Strategy</p>
                                            </div>
                                        </div>
                                        <svg width={85} height={65} xmlns="http://www.w3.org/2000/svg">
                                            <text transform="rotate(-180 475.5 56.5)" fill="#EDF2F7" fillRule="evenodd" fontFamily="ProximaNova-Extrabld, Proxima Nova" fontSize={200} fontWeight={600} letterSpacing="1.667">
                                                <tspan x={860} y={184}>
                                                    “
                                                </tspan>
                                            </text>
                                        </svg>
                                    </div>
                                    <p className="text-base text-gray-600 pb-6 w-full sm:w-10/12">The company really helped me with my personal branding. Everything from conception to execution was extremely awesome and really professional. I enjoyed the services they offered. Their design is impeccable.</p>
                                    <p className="text-base text-gray-600 w-full sm:w-10/12">I truly value the relationship they created with me. I will always think first about them while starting any projects in the future. They are definitely my go-to for any design or services. Excellent communication and I always feel that I am their first priority.</p>
                                    <img src="https://cdn.tuk.dev/assets/Path-right-arrow.png" alt className="absolute right-0 mr-16 sm:-mt-6 cursor-pointer" onClick={() => setChange(!change)} />
                                </div>
                            </figure>
                        </section>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    
    
    )
}